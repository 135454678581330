import React, { useState } from "react";

import { Link } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Categories from "../components/categories";
import Tools from "../components/tools";

const CategoriesPage = ({ pageContext }) => {
  const { categories, tools } = pageContext;

  return (
    <Layout categories={categories} bg={"white"}>
      <Seo title="Categories" />
      <div className="bg-white px-4">
        <div className="max-w-5xl mx-auto">
          <Categories categories={categories} />
        </div>
      </div>
    </Layout>
  );
};
export default CategoriesPage;
